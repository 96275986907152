<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content" @scroll="handleScroll">
      <section class="section">
        <div class="d-flex mb-4 align-items-center">
          <div class="title mr-auto">
            <h4 class="title">Akun Perkiraan</h4>
          </div>
          <form class="search mr-3" @submit.prevent="onSearch">
            <input
              type="text"
              v-model="search"
              @keyup="searchCheck"
              id="searchbox"
              placeholder="Cari..."
              name="search"
            />
            <button type="submit"><i class="fa fa-search"></i></button>
          </form>
          <router-link
            to="/data-jurnal-perkiraan/tambah"
            class="btn-add"
            v-if="
              checkAccess('ledger') ||
              checkAccess('ledger', 'cru_acoount_estimates')
            "
            >+ Tambah
          </router-link>
        </div>
        <div class="card-table">
          <div class="d-flex mb-4 align-items-center">
            <Select2
              v-model="id_tipe"
              :options="OptionAccountType"
              placeholder="Pilih Tipe Akun"
              class="mr-auto"
              style="width: 200px"
              :settings="{ allowClear: true }"
              @change="myChangeEvent($event)"
              @select="myChangeEvent($event)"
            />
          </div>
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="tableDepartment"
            >
              <thead>
                <tr>
                  <th style="width: 15%">Kode Perkiraan</th>
                  <th style="width: 20%">Nama</th>
                  <!-- <th style="width: 20%">Departemen</th> -->
                  <th style="width: 20%">Tipe Akun</th>
                  <th style="width: 20%">Saldo</th>
                  <!-- <th style="width:3%">Status</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(value, index) in list"
                  :key="index"
                  v-on:click="detail(value.id)"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                  :class="{
                    'first-parent': value.hasChild == 1 && value.sub_level == 0,
                    'second-parent': value.hasChild == 1 && value.sub_level == 1,
                  }"
                >
                  <td
                    :class="{
                      depth1: value.sub_level === '1',
                      depth2: value.sub_level === '2',
                    }"
                  >
                    {{ value.id }}
                  </td>
                  <td
                    :class="{
                      depth1: value.sub_level === '1',
                      depth2: value.sub_level === '2',
                    }"
                  >
                    {{ value.nama }}
                  </td>
                  <!-- <td>{{ value.nama_divisi ? value.nama_divisi : "-" }}</td> -->
                  <td>{{ value.tipe }}</td>
                  <td class="text-right">
                    {{ value.saldo ? formatMoney(value.saldo) : 0 }}
                  </td>
                  <!-- <td>
                                        <div class="green-bedge" v-if="value.status == 'aktif'">Aktif</div>
                                        <div class="red-bedge" v-else>Nonaktif</div>
                                    </td> -->
                </tr>
                <tr v-if="onGetData">
                  <td colspan="4" class="text-center">
                    mengambil data &nbsp;&nbsp;<i
                      class="fas fa-spinner fa-spin"
                    ></i>
                  </td>
                </tr>
                <tr v-if="list.length === 0 && !onGetData">
                  <td colspan="4" class="text-center">Tidak ada data</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import {
  get_AkunList,
  get_AkunTipeList,
} from "../../../actions/akun_perkiraan";
import { cksClient, checkModuleAccess } from "../../../helper";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // TableLite
  },
  data() {
    return {
      checkAccess: checkModuleAccess,
      OptionAccountType: [],
      list: [],
      id_tipe: "",
      id_company: cksClient().get("_account").id_company,
      search: "",
      dataTable: "",
      isSearch: false,
      page: 1,
      onGetData: false,
      isLast: false,
    };
  },

  created() {
    this.getTipe();
    this.getList();
  },

  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },

  methods: {
    handleScroll(event) {
      if (
        event.target.scrollHeight ==
        Math.round(
          event.target.getBoundingClientRect().bottom + event.target.scrollTop
        )
      ) {
        if (!this.onGetData && !this.isLast) {
          this.page += 1;
          this.getList();
        }
        this.getList();
        // alert("bottom");
      }
    },
    myChangeEvent() {
      this.onGetData = false;
      this.page = 1;
      this.isLast = false;
      this.getList();
    },
    searchCheck: function () {
      if (!this.search && this.isSearch) {
        this.isSearch = false;
        this.getList();
      } else {
        this.isSearch = true;
      }
    },
    async onSearch() {
      if (this.search || this.isSearch) {
        this.page = 1;
        this.isLast = false;
        this.isSearch = true;
        this.getList();
      }
      if (!this.search && this.isSearch) {
        this.isSearch = false;
      }
    },
    getTipe() {
      get_AkunTipeList((res) => {
        this.OptionAccountType = res.list;
      });
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(id) {
      $('[data-toggle="tooltip"]').tooltip("hide");
      this.$router.push({
        name: "DetailEstimatedAccount",
        params: {
          id: id,
        },
      });
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getList() {
      if (!this.onGetData && !this.isLast) {
        this.onGetData = true;
        get_AkunList(
          {
            id_company: this.id_company,
            search: this.search,
            tipe: this.id_tipe,
            page: this.page,
          },
          (res) => {
            this.onGetData = false;
            if (this.page > 1) {
              this.list = [...this.list, ...res.list.data];
            } else {
              this.list = [];
              this.list = res.list.data;
            }
            if (
              parseInt(res.list.pagination.currentPage) ===
              res.list.pagination.lastPage
            ) {
              this.isLast = true;
            }
            // this.isLast = true;
            $('[data-toggle="tooltip"]').tooltip("hide");
            // if (this.dataTable) {
            //     this.dataTable.destroy()
            // }
            setTimeout(() => {
              // this.dataTable = $('#tableDepartment').DataTable({
              //     info: false,
              // });
              this.tooltip();
            }, 1000);
          }
        );
      }
    },
    sumSubLevelParent(id) {
      var data = 0;
      var listData = this.list.filter((value) => value.id_parent === id);
      for (const key in listData) {
        if (Object.hasOwnProperty.call(listData, key)) {
          const element = listData[key];
          data += element.saldo;
        }
      }
      return data;
    },
  },
};
</script>

<style scoped>
.select2 {
  width: 150px !important;
}

.depth1 {
  padding-left: 40px !important;
}

.depth2 {
  padding-left: 60px !important;
}

.depth3 {
  padding-left: 90px !important;
}

.first-parent td, .second-parent td {
  font-weight: bold;
}

.first-parent td {
  color: #FF3D3D;
}

</style>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>
